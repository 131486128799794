export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@mentoring-platform/views/pages/Home.vue'),
    meta: {
      action: 'read',
      resource: 'public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/mentor/register',
    name: 'mentor-register',
    component: () => import('@mentoring-platform/views/pages/MentorRegistration.vue'),
    meta: {
      action: 'read',
      resource: 'not-mentor',
    },
  },
  {
    path: '/mentee/register',
    name: 'mentee-register',
    component: () => import('@mentoring-platform/views/pages/MenteeRegistration.vue'),
    meta: {
      action: 'read',
      resource: 'not-mentee',
    },
  },
  {
    path: '/mentoring-packages',
    name: 'mentoring-packages',
    component: () => import('@mentoring-platform/views/pages/MentoringPackages.vue'),
    meta: {
      action: 'read',
      resource: 'public',
    },
  },
  {
    path: '/mentors',
    name: 'our-mentors',
    component: () => import('@mentoring-platform/views/pages/Mentors.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      contentRenderer: 'sidebar-left-detached',
      pageHeader: {
        title: 'Our mentors',
        message: 'Our mentors description',
      },
      action: 'read',
      resource: 'public',
    },
  },
  {
    path: '/mentors/:id',
    name: 'mentor-page',
    component: () => import('@mentoring-platform/views/pages/Mentor.vue'),
    meta: {
      action: 'read',
      resource: 'public',
    },
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('@mentoring-platform/views/pages/Help.vue'),
    meta: {
      action: 'read',
      resource: 'public',
    },
  },
  {
    path: '/faq/:category',
    name: 'pages-knowledge-base-category',
    component: () => import('@mentoring-platform/views/pages/HelpCategory.vue'),
    meta: {
      action: 'read',
      resource: 'public',
    },
  },
  {
    path: '/terms-and-conditions',
    name: 'terms-and-conditions',
    component: () => import('@mentoring-platform/views/pages/TermsAndConditions.vue'),
    meta: {
      action: 'read',
      resource: 'public',
    },
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('@mentoring-platform/views/pages/PrivacyPolicy.vue'),
    meta: {
      action: 'read',
      resource: 'public',
    },
  },
  {
    path: '/cookies-policy',
    name: 'cookies-policy',
    component: () => import('@mentoring-platform/views/pages/CookiesPolicy.vue'),
    meta: {
      action: 'read',
      resource: 'public',
    },
  },
  {
    path: '/mentoring-terms',
    name: 'mentoring-terms',
    component: () => import('@mentoring-platform/views/pages/MentoringTerms.vue'),
    meta: {
      action: 'read',
      resource: 'public',
    },
  },
]
